<template>
  <section class="image-upload-section">
    <h1
      :class="['image-upload-section-header', { 'has-sub-header': subHeader }]">
      {{ title }}
    </h1>

    <div>
      <p class="image-upload-section-sub-header" v-if="subHeader">
        {{ subHeader }}
      </p>
    </div>
    <div>
      <div
        class="image-upload-section-preview-container"
        :style="{ 'background-color': backgroundColor }"
        @click="clickUpload">
        <img
          v-if="selectedImage"
          :src="selectedImage"
          alt="Image Preview"
          class="image-upload-section-preview" />
      </div>
      <input
        type="file"
        @change="fileSelected"
        :accept="mimeTypes"
        name="file"
        id="file"
        ref="fileupload"
        class="image-upload-section-upload-input" />
    </div>
    <div class="image-upload-section-upload-container">
      <common-button variant="secondary" for="file" @click="clickUpload">
        {{ $lang.settingsPage_UploadButton }}
      </common-button>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },

      subHeader: {
        type: String,
        default: null,
      },

      selectedImage: {
        type: String,
      },

      backgroundColor: {
        type: String,
      },

      mimeTypes: {
        type: String,
        default: "image/png",
      },
    },

    methods: {
      clickUpload() {
        this.$refs["fileupload"].click();
      },

      fileSelected(file) {
        this.$emit("upload", file);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-upload-section {
    margin-top: 2.5rem;
  }

  .image-upload-section-header {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;

    &.has-sub-header {
      margin-bottom: 1rem;
    }
  }

  .image-upload-section-preview-container {
    padding: 1rem;
    border: 1px;
    border-radius: 0.375rem;
    cursor: pointer;
    background-color: $grey-100;
    width: 18rem;
    height: 6rem;
  }

  .image-upload-section-preview {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .image-upload-section-upload-input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .image-upload-section-upload-container {
    width: 8rem;
  }

  .image-upload-section-sub-header {
    color: $grey-500;
    margin: 0;
    margin-bottom: 2rem;
    font-size: 0.875rem;
  }
</style>
