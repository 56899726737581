<template>
  <div class="branding">
    <!-- Header -->
    <div>
      <h1 class="branding-section-header has-sub-header">
        {{ $lang.settingsPage_Branding }}
      </h1>
    </div>
    <div>
      <p class="branding-tab-description">
        {{ $lang.settingsPage_BrandingSubHeader }}
      </p>
    </div>

    <!-- Logo -->
    <image-upload-section
      :title="$lang.settingsPage_LogoHeader"
      :background-color="formatColor(colorOne)"
      :selected-image="selectedLogo"
      @upload="fileSelected" />

    <!-- Favicon -->
    <image-upload-section
      class="branding-section-favicon-upload"
      :title="$lang.settingsPage_FaviconHeader"
      :sub-header="$lang.settingsPage_FaviconSubHeader"
      mimeTypes="image/png, image/x-icon"
      :selected-image="favicon"
      @upload="fileSelected($event, true)" />

    <!-- Primary Color -->
    <section class="branding-section">
      <h1 class="branding-section-header">
        {{ $lang.settingsPage_ColorsHeader }}
      </h1>

      <section>
        <div class="branding-section-color-header">
          {{ $lang.settingsPage_ColorsPrimarySectionHeader }}
          <p>
            <em>
              {{ $lang.settingsPage_ColorsPrimarySectionSubHeader }}
            </em>
          </p>
        </div>
        <div class="branding-section-color-hex-container">
          <div
            class="branding-section-color-hex-preview branding-section-color-item"
            v-bind:style="{ 'background-color': formatColor(colorOne) }" />
          <div
            class="branding-section-hex-input-container branding-section-color-item">
            <common-input
              :value="hexOne"
              @keyup="updateHex($event, 'hexOne')"
              icon>
              <div class="branding-section-color-input-label">
                {{ $lang.settingsPage_ColorsHexLabel }}
              </div>
            </common-input>
          </div>
          <p
            class="branding-section-color-or-label branding-section-color-item">
            {{ $lang.settingsPage_ColorsOrLabel }}
          </p>
          <div class="branding-section-color-item flex items-center">
            <div class="branding-section-color-rgb-wrapper">
              <common-input
                class="h-full"
                width="w-20"
                type="number"
                :value="rgbOne[0]"
                @keyup="updateRGB($event, 'rgbOne', 0)"
                icon>
                <div class="branding-section-color-input-label">
                  {{ $lang.settingsPage_ColorsRLabel }}
                </div>
              </common-input>
            </div>
            <div class="branding-section-color-rgb-wrapper">
              <common-input
                class="h-full"
                width="w-20"
                type="number"
                :value="rgbOne[1]"
                @keyup="updateRGB($event, 'rgbOne', 1)"
                icon>
                <div class="branding-section-color-input-label">
                  {{ $lang.settingsPage_ColorsGLabel }}
                </div>
              </common-input>
            </div>
            <common-input
              width="w-20"
              type="number"
              :value="rgbOne[2]"
              @keyup="updateRGB($event, 'rgbOne', 2)"
              icon>
              <div class="branding-section-color-input-label">
                {{ $lang.settingsPage_ColorsBLabel }}
              </div>
            </common-input>
          </div>
        </div>
      </section>

      <section class="branding-section">
        <div class="branding-section-color-header">
          {{ $lang.settingsPage_ColorsSecondarySectionHeader }}
          <p>
            <em>
              {{ $lang.settingsPage_ColorsSecondarySectionSubHeader }}
            </em>
          </p>
        </div>
        <div class="branding-section-color-hex-container">
          <div
            class="branding-section-color-hex-preview branding-section-color-item"
            v-bind:style="{ 'background-color': formatColor(colorTwo) }" />
          <div
            class="branding-section-hex-input-container branding-section-color-item">
            <common-input
              class="h-full"
              :value="hexTwo"
              @keyup="updateHex($event, 'hexTwo')"
              icon>
              <div class="branding-section-color-input-label">
                {{ $lang.settingsPage_ColorsHexLabel }}
              </div>
            </common-input>
          </div>
          <p
            class="branding-section-color-or-label branding-section-color-item">
            {{ $lang.settingsPage_ColorsOrLabel }}
          </p>
          <div class="branding-section-color-item flex items-center">
            <div class="branding-section-color-rgb-wrapper">
              <common-input
                class="h-full"
                width="w-20"
                type="number"
                :value="rgbTwo[0]"
                @keyup="updateRGB($event, 'rgbTwo', 0)"
                icon>
                <div class="branding-section-color-input-label">
                  {{ $lang.settingsPage_ColorsRLabel }}
                </div>
              </common-input>
            </div>
            <div class="branding-section-color-rgb-wrapper">
              <common-input
                class="h-full"
                width="w-20"
                type="number"
                :value="rgbTwo[1]"
                @keyup="updateRGB($event, 'rgbTwo', 1)"
                icon>
                <div class="branding-section-color-input-label">
                  {{ $lang.settingsPage_ColorsGLabel }}
                </div>
              </common-input>
            </div>
            <common-input
              width="w-20"
              type="number"
              :value="rgbTwo[2]"
              @keyup="updateRGB($event, 'rgbTwo', 2)"
              icon>
              <div class="branding-section-color-input-label">
                {{ $lang.settingsPage_ColorsBLabel }}
              </div>
            </common-input>
          </div>
        </div>
      </section>

      <section class="branding-section">
        <div class="branding-section-color-header">
          {{ $lang.settingsPage_ColorsAccentSectionHeader }}
          <p>
            <em>
              {{ $lang.settingsPage_ColorsAccentSectionSubHeader }}
            </em>
          </p>
        </div>
        <div class="branding-section-color-hex-container">
          <div
            class="branding-section-color-hex-preview branding-section-color-item"
            v-bind:style="{ 'background-color': formatColor(colorThree) }" />
          <div
            class="branding-section-hex-input-container branding-section-color-item">
            <common-input
              class="h-full"
              :value="hexThree"
              @keyup="updateHex($event, 'hexThree')"
              icon>
              <div class="branding-section-color-input-label">
                {{ $lang.settingsPage_ColorsHexLabel }}
              </div>
            </common-input>
          </div>
          <p
            class="branding-section-color-or-label branding-section-color-item">
            {{ $lang.settingsPage_ColorsOrLabel }}
          </p>
          <div class="branding-section-color-item flex items-center">
            <div class="branding-section-color-rgb-wrapper">
              <common-input
                class="h-full"
                width="w-20"
                type="number"
                :value="rgbThree[0]"
                @keyup="updateRGB($event, 'rgbThree', 0)"
                icon>
                <div class="branding-section-color-input-label">
                  {{ $lang.settingsPage_ColorsRLabel }}
                </div>
              </common-input>
            </div>
            <div class="branding-section-color-rgb-wrapper">
              <common-input
                class="h-full"
                width="w-20"
                type="number"
                :value="rgbThree[1]"
                @keyup="updateRGB($event, 'rgbThree', 1)"
                icon>
                <div class="branding-section-color-input-label">
                  {{ $lang.settingsPage_ColorsGLabel }}
                </div>
              </common-input>
            </div>
            <common-input
              width="w-20"
              type="number"
              :value="rgbThree[2]"
              @keyup="updateRGB($event, 'rgbThree', 2)"
              icon>
              <div class="branding-section-color-input-label">
                {{ $lang.settingsPage_ColorsBLabel }}
              </div>
            </common-input>
          </div>
        </div>
      </section>
    </section>

    <common-button variant="primary" class="btn-save" @click="saveBranding">
      Save
    </common-button>
  </div>
</template>

<script>
  import { SET_BRANDING } from "@/store/actions.type.js";
  import defaultFavicon from "@/assets/default-favicon.png";
  import ImageUploadSection from "./ImageUploadSection.vue";
  import { resizeImage } from "@/shared/utils";

  export default {
    components: {
      ImageUploadSection,
    },

    props: ["branding"],
    data() {
      return {
        selectedLogo: null,
        logoUploaded: false,
        colorOne: "",
        colorTwo: "",
        colorThree: "",
        rgbOne: [0, 0, 0],
        rgbTwo: [0, 0, 0],
        rgbThree: [0, 0, 0],
        hexOne: "",
        hexTwo: "",
        hexThree: "",
        favicon: defaultFavicon,
        isFaviconUploaded: false,
      };
    },

    created() {
      if (this.branding.logoUrl) {
        this.selectedLogo = this.branding.logoUrl;
      }

      if (this.branding.faviconUrl) {
        this.favicon = this.branding.faviconUrl;
      }

      this.hexOne = this.colorOne = this.branding.color;
      this.rgbOne = this.switchColors(this.branding.color);
      this.hexTwo = this.colorTwo = this.branding.accentColor;
      this.rgbTwo = this.switchColors(this.branding.accentColor);

      if (this.branding.backColor) {
        this.hexThree = this.colorThree = this.branding.backColor;
        this.rgbThree = this.switchColors(this.branding.backColor);
      } else {
        this.hexThree = this.colorThree = "#ffea00";
        this.rgbThree = this.switchColors("#ffea00");
      }
    },

    methods: {
      clickUpload() {
        this.$refs["fileupload"].click();
      },

      clickUploadFavicon() {
        this.$refs.favicon.click();
      },

      fileSelected(event, isFavicon) {
        let image = event.target.files[0];
        let fileSize = event.target.files[0].size;
        let reader = new FileReader();

        // scale down for uploading, backend will compress
        if (fileSize > 100000) {
          reader.readAsArrayBuffer(image);
          reader.onload = async () => {
            const smallestSide = isFavicon ? 48 : 200;

            if (isFavicon) {
              this.favicon = await resizeImage(
                reader.result,
                smallestSide,
                image.type
              );
              this.isFaviconUploaded = true;
            } else {
              this.selectedLogo = await resizeImage(
                reader.result,
                smallestSide,
                image.type
              );
              this.logoUploaded = true;
            }
          };
        } else {
          reader.readAsDataURL(image);
          reader.onload = (event) => {
            if (isFavicon) {
              this.favicon = event.target.result;
              this.isFaviconUploaded = true;
            } else {
              this.selectedLogo = event.target.result;
              this.logoUploaded = true;
            }
          };
        }
      },
      previewColor(num) {
        //TODO I mean, have they even used vue before. Redo this whole file
        if (num == 1) {
          let rgb = this.rgbOne;
          this.hexOne = this.switchColors(rgb);
          this.colorOne = this.hexOne.toString();
        } else if (num == 2) {
          let hex = this.hexOne;
          this.rgbOne = this.switchColors(hex);
          this.colorOne = hex.toString();
        } else if (num == 3) {
          let rgb = this.rgbTwo;
          this.hexTwo = this.switchColors(rgb);
          this.colorTwo = this.hexTwo.toString();
        } else if (num == 4) {
          let hex = this.hexTwo;
          this.rgbTwo = this.switchColors(hex);
          this.colorTwo = hex.toString();
        } else if (num == 5) {
          let rgb = this.rgbThree;
          this.hexThree = this.switchColors(rgb);
          this.colorThree = this.hexThree.toString();
        } else if (num == 6) {
          let hex = this.hexThree;
          this.rgbThree = this.switchColors(hex);
          this.colorThree = hex.toString();
        }
      },
      formatColor(color) {
        const regex = new RegExp(/(^[#])([a-zA-Z0-9]{1,6})/);
        if (color.match(regex)) return color;
        else return "#ffffff";
      },
      switchColors(color) {
        function componentToHex(c) {
          let hex = c.toString(16);
          return hex.length == 1 ? "0" + hex : hex;
        }

        if (typeof color == "string") {
          //hex to rgb and return rgb array
          let colorString = color.substring(1);
          let rgb = parseInt(colorString, 16);
          let r = (rgb >> 16) & 0xff;
          let g = (rgb >> 8) & 0xff;
          let b = (rgb >> 0) & 0xff;

          return [r, g, b];
        } else {
          //rgb to hex and return hex string
          let r = parseInt(color[0]);
          let g = parseInt(color[1]);
          let b = parseInt(color[2]);

          return (
            "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
          );
        }
      },
      updateRGB(value, rgbNumber, rgbPosition) {
        if (value) {
          this[rgbNumber][rgbPosition] = value;
        } else {
          this[rgbNumber][rgbPosition] = 0;
        }
        switch (rgbNumber) {
          case "rgbOne":
            return this.previewColor(1);
          case "rgbTwo":
            return this.previewColor(3);
          case "rgbThree":
            return this.previewColor(5);
        }
      },
      updateHex(value, hexNumber) {
        const regex = new RegExp(/(^[#])([a-zA-Z0-9]{0,6})/);
        if (value.match(regex)) this[hexNumber] = value;

        switch (hexNumber) {
          case "hexOne":
            return this.previewColor(2);
          case "hexTwo":
            return this.previewColor(4);
          case "hexThree":
            return this.previewColor(6);
        }
      },
      saveBranding: async function () {
        const regex = new RegExp(/(^[#])([a-zA-Z0-9]{6})$/);
        if (
          !this.colorOne.match(regex) ||
          !this.colorTwo.match(regex) ||
          !this.colorThree.match(regex)
        ) {
          return this.$toasted.show(
            this.$lang.settingsPage_InvalidHexError,
            this.$toastedFailure
          );
        }
        const data = {};

        if (this.logoUploaded) {
          data.logo = this.selectedLogo;
        }

        if (this.isFaviconUploaded) {
          data.favicon = this.favicon;
        }

        data.color = this.colorOne;
        data.accentColor = this.colorTwo;
        data.backColor = this.colorThree;
        const response = await this.$store.dispatch(SET_BRANDING, data);
        if (response) {
          this.$toasted.show(
            this.$lang.settingsPage_BrandingSavedSuccess,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedFailure
          );
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .branding {
    padding-bottom: 20px;
  }

  .branding-section {
    margin-top: 2.5rem;
  }

  .branding-section-header {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;

    &.has-sub-header {
      margin-bottom: 1rem;
    }
  }

  .branding-tab-description {
    color: $grey-500;
    margin: 0;
    font-size: 0.875rem;
  }

  ::v-deep
    .branding-section-favicon-upload
    .image-upload-section-preview-container {
    width: 98px;
    height: 98px;
  }

  .branding-section-color-header {
    color: $grey-500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .branding-section-color-hex-container {
    display: flex;
    align-items: center;
  }

  .branding-section-color-hex-preview {
    border-width: 1px;
    border-radius: 0.5rem;
    margin-right: 2.25rem;
    height: 3rem;
    width: 3rem;
  }

  .branding-section-hex-input-container {
    width: 7.5rem;
  }

  .branding-section-color-input-label {
    color: $secondary-text;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .branding-section-color-or-label {
    margin: 1rem;
    color: $secondary-text;
  }

  .branding-section-color-rgb-wrapper {
    margin-right: 0.5rem;
  }

  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }

  @media screen and (max-width: 768px) {
    .branding-section-color-hex-container {
      flex-direction: column;
      align-items: initial;
    }

    .branding-section-color-hex-preview {
      margin-right: 0;
    }

    .branding-section-color-item {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .branding-section-hex-input-container {
      width: 16rem;
    }

    .branding-section-color-input-label {
      display: none;
    }
  }
</style>
